@import '../../transitions.module.scss';

.homePage {
  display: grid;
  height: 100%;
  width: 100%;
  background-image: url('../../assets/Zal_Composition.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  grid-template-columns: 1fr auto 1fr;
}

.homeNav {
  background-color: #2E3192;
  padding: 64px;
  display: grid;
  grid-template-areas:
    "about work"
    "logo logo"
    "contact resume";
  grid-template-rows: auto 1fr auto;
  align-items: center;
  gap: 32px;
}

.aboutLink,
.workLink,
.contactLink,
.resumeLink {
  font-size: 1.4rem;
  opacity: 0;
  @include fadeInAnimation;
}

.aboutLink {
  justify-content: flex-start;
  grid-area: about;
  animation-delay: 1s;
}

.workLink {
  justify-content: flex-end;
  grid-area: work;
  animation-delay: 2s;
}

.contactLink {
  justify-content: flex-start;
  grid-area: contact;
  animation-delay: 3s;
}

.resumeLink {
  grid-area: resume;
  justify-content: flex-end;
  animation-delay: 4s;
}

.logoContainer {
  grid-area: logo;
  min-height: 100%;
  height: 0;
  @include fadeInAnimation;
  animation-duration: 3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}

.logoText {
  font-size: 2rem;
  color: white;
}
