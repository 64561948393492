@import '../../transitions.module.scss';

.aboutPage {
  height: 100%;
  width: 100%;
  overflow: auto;

  @media only screen and (min-width: 768px) {
    display: flex;
    overflow: hidden;
  }
}

.picturePanel {
  background-color: #E4C32E;
  padding: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  @include slideLeftAnimation;

  @media only screen and (min-width: 768px) {
    width: 40%;
    @include slideUpAnimation;
  }
}

.profilePicContainer {
  position: relative;
  width: 100%;
  padding-bottom: 50%;

  @media only screen and (min-width: 768px) {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
}

.profilePic {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  object-position: center 30%;
  border-radius: 50%;
  border: 4px solid black;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    width: 100%;
    left: 0;
    transform: none;
  }
}

.divider {
  background-color: black;
  width: 100%;
  height: 6px;

  @media only screen and (min-width: 768px) {
    width: 6px;
    height: 100%;
  }
}

.aboutPanel {
  @include slideRightAnimation
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  gap: 8px;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    padding: 16px 16px 16px 100px;
    @include slideLeftAnimation;
  }

  .title {
    color: #BE1E2D;
    font-size: 4rem;
    margin-bottom: 1.5rem;
    margin-top: auto;
    text-align: center;

    @media only screen and (min-width: 768px) {
      text-align: left;
    }
  }

  .bodyText {
    font-size: 1.1rem;
    line-height: 1.6rem;
    white-space: pre-wrap;
    margin-bottom: auto;
    width: 100%;

    @media only screen and (min-width: 768px) {
      width: 70%;
    }

    a {
      color: #BE1E2D;
      text-decoration: underline;
    }
  }
}
