.header {
  position: fixed;
  height: 70px;
  width: 100%;
  background-color: #2E3192;
  padding: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr auto 1fr 1fr;
  grid-gap: 16px;
  align-items: center;
  box-sizing: border-box;
  z-index: 1;
  border-bottom: 4px solid black;

  @media only screen and (min-width: 768px) {
    padding: 8px 64px;
  }
}

.headerLogo {
  height: 100%;
}

.headerLink {
  display: flex;
  width: auto;
  justify-content: center;

  .text {
    position: relative;
    text-decoration: none;
    color: white;

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 0.1em;
      background-color: white;
      transform: scaleX(0);
      transition: transform 300ms;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }
}
