@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeRise {
  0% {
    opacity: 0;
    transform: translateY(200%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@mixin slideUpAnimation {
  animation: 1.5s cubic-bezier(.2,.52,.37,1) 0s 1 slideUp;
}

@mixin slideLeftAnimation {
  animation: 1.5s cubic-bezier(.2,.52,.37,1) 0s 1 slideLeft;
}

@mixin slideRightAnimation {
  animation: 1.5s cubic-bezier(.2,.52,.37,1) 0s 1 slideRight;
}

@mixin fadeInAnimation {
  animation: 1s ease-in-out 0s normal forwards 1 fadeIn;
}

@mixin fadeRiseAnimation {
  animation: 1s ease-in-out 0s normal forwards 1 fadeRise;
}
