@import '../../transitions.module.scss';

.workPage {
  display: grid;
  grid-template-areas:
    "one two"
    "three three";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.workLink {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  @include fadeInAnimation;

  .workLinkImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.4s ease-in-out, filter 0.4s ease-in-out;
  }

  .workLinkTitle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    font-size: 1.3rem;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .workLinkDescription {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  &:hover {
    .workLinkTitle {
      opacity: 0;
    }

    .workLinkImage {
      transform: scale(120%);
      filter: brightness(30%);
    }

    .workLinkDescription {
      opacity: 1;
    }
  }
}
