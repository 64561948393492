@import '../../transitions.module.scss';

.contactPage {
  display: grid;
  height: fit-content;
  min-height: 100%;
  width: 100%;
  padding: 32px;
  background-color: #E4C32E;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  box-sizing: border-box;
}

.contactMeSection {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  font-size: 3rem;
  color: #BE1E2D;
}

.contactLinks {
  display: flex;
  gap: 32px;
  flex-direction: column;

  @for $i from 1 through 4 {
    a:nth-child(#{$i}n) {
      animation-delay: #{($i * 0.5) - 0.5}s;
    }
  }
}

.contactLink {
  display: flex;
  gap: 8px;
  align-items: center;
  color: black;
  font-size: 1.4rem;
  text-decoration: none;
  transition: color .2s ease-in-out;
  opacity: 0;
  @include fadeRiseAnimation;

  .linkIcon {
    height: 32px;
    width: 32px;
  }

  &:hover {
    color: #BE1E2D;
  }
}
